import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import ResizeObserver from "resize-observer-polyfill";

export const useRefState = function <T>(t: T|(()=>T)): [T, (t: T) => void, () => T] {

    const [state, setState] = useState(t)

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    const ref = useRef({setState, state});
    ref.current = {setState, state};
    return [state, (t: T) => {
        if (mounted.current) {

            ref.current.setState(t);
        }

    }, () => {
        return ref.current.state;
    }];

}


/**
 * General utils for managing cookies in Typescript.
 *
 * @param name
 * @param val
 * @param time (v sekudach)
 */
export function setCookie(name: string, val: string, time: number = 0) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    if (time)
        date.setTime(date.getTime() + time * 1000);
    else
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));


    // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/; SameSite=None; Secure";
}

export function getCookie(name: string): string | undefined {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        const d = parts.pop();
        if (d)
            return d.split(";").shift();
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}


export const makeCss = (left: number, uuid: string, key: number, second: number) => {


    const spin = `
                    0% { transform: translateX(${0}px); -webkit-transform: translateX(${0}px); } 
                    45% { transform: translateX(${left}px);-webkit-transform: translateX(${left}px); }
                    55% { transform: translateX(${left}px);-webkit-transform: translateX(${left}px); } 
                    90% { transform: translateX(${0}px); -webkit-transform: translateX(${0}px); }
                    100% { transform: translateX(${0}px); -webkit-transform: translateX(${0}px); }
    `;
    const keyFrames = `
                    @keyframes keyframe-${uuid}-${key} {
                        ${spin}
                    }
                    @-webkit-keyframes keyframe-${uuid}-${key} {
                        ${spin}
                    }
                    .running-text-${uuid}-${key} {
                        animation: keyframe-${uuid}-${key} ${second}s infinite ease-in-out both;
                        -webkit-animation: keyframe-${uuid}-${key} ${second}s infinite ease-in-out both;
                        
                    }
                    
                    `
    return keyFrames;
}


export const makeUUID = () => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const domconsole = (divconsole: HTMLElement, iserr: boolean, ...args: any) => {

    console[iserr ? 'error' : 'log'](...args);

    //const div = iserr ? `<div style="color:white;background-color: red">${args.map(i=>i+'').join(', ')}</div>` : `<div style="color:black;background-color: white">${args.map(i=>i+'').join(', ')}</div>`;
    const div = document.createElement('div');
    if (iserr)
        Object.assign(div.style, {
            color: 'white',
            backgroundColor: 'red'
        })
    else
        Object.assign(div.style, {
            color: 'black',
            backgroundColor: 'white'
        })
    div.innerHTML = args.map(i => i + '').join(', ');
    divconsole.appendChild(div);

}


export const getRect = (width:number, height: number) => {
    height = width * 170/300;

    if (height < 170 && width >= 300)
        height = 170;
    else
        height = Math.floor(height);

    return {
        width,
        height
    }
}

export function useResponsiveBreakpoints(el: HTMLElement | null) {

    const ref = useRef(el);

    const [rect, setRect, getRect1] = useRefState(() => {
        if (el) {
            return getRect(el.offsetWidth, el.offsetHeight);
        } else {
            return getRect(0, 0);
        }
    });
    const timeoutReference = useRef<ReturnType<typeof setTimeout>|null>(null)

    const timeout = () => {
        if (timeoutReference.current)
            clearTimeout(timeoutReference.current);

        timeoutReference.current = null;
        let r = getRect(0,0);

        if (ref.current) {
            r = getRect(ref.current.offsetWidth, ref.current.offsetHeight);
        }
        const o = getRect1();
        if (o.height !== r.height || o.width !== r.width)
            setRect(r);
    }

    const [observer] = useState(()=> new ResizeObserver((entries) => {
        if (timeoutReference.current)
            return;

        timeoutReference.current = setTimeout(timeout, 200);
    }));

    useEffect(() => {

        if (ref.current) {
            observer.unobserve(ref.current);
            ref.current = null;
        }

        if (el) {
            ref.current = el;
            observer.observe(el);
            timeout();
        }

    }, [ref.current, el]);

    useEffect(() => {
        return () => {
            observer.disconnect();
        }
    }, [])
    return rect;
}