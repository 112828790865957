import React, {useEffect, useRef, useState} from "react";
import {PlayerStateContext} from "../Reducer";
import './Text.scss';
import { makeUUID, makeCss } from "../Utils";

export const Text = (props: {
    title: string,
    desc?:string,
    mode: 'black'|'white',
    size: {
      width: number,
      height: number
    },
    element: HTMLElement
})=>{

    const [divsCss, setDivsCss] = useState('');
    const div = useRef(
        null as HTMLDivElement|null
    );
    const parent = useRef(
        null as HTMLDivElement|null
    );
    const [uuid] = useState(()=>makeUUID());

    useEffect(()=>{

        let newCss = '';

        const divWidth = div.current?.clientWidth || 0;
        const parentWidth = parent.current ? parent.current.offsetWidth : 0;

        if (divWidth)
        {
            if (divWidth > parentWidth) {
                const left = parentWidth - divWidth;
                newCss += makeCss(left, uuid, 1, Math.ceil(8 * (divWidth / (parentWidth))));
            }
            else {
                newCss += makeCss(0, uuid, 1, Math.ceil(8 * (divWidth / (parentWidth))));
            }
        }

        setDivsCss(newCss);

    }, [props.title || '', props.desc || '', props.size.width, props.size.height])

    const color = props.mode;


    return <>
        {divsCss ? <style>{divsCss}</style> : false}
        <div  ref={parent} className={'helax-player-text-overlay'}>
        <div ref={div} className={`helax-player-text  running-text-${uuid}-1`}>
            {props.title ? <span style={{color}}  className={`title`}>{props.title}</span> : false}
            {props.desc ? <span style={{color}} className={`desc`}>{props.desc}</span> : false}
        </div>
        </div>
    </>

}