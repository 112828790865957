import {getCookie, setCookie} from "../Utils";

/**
 * Position
 * potrebuje id (srcid) pro ulozeni prehravane pozice.
 * Uklada bud to storage (nebo cookies)
 *
 * property: duration, time
 * setTimeAndStop: nastavi cas a znemozni ukladani, dokud se nevytvori nova instance
 *
 */

export class Position {

    protected _time: number;

    protected _srcid: string;

    protected _duration: number = 0;

    protected save() {

    }

    protected load() {

    }

    public clone () {

        return new Position(this._srcid, this._duration);
    }

    public constructor(srcid: string, duration: number|undefined = undefined) {

        this._srcid = srcid;
        this._duration = 0;
        this._time = 0;
        if (duration !== undefined)
            this._duration = duration;

        if (typeof window['localStorage'] !== "undefined") {
            this.save = () => {
                localStorage.setItem(this._srcid, (`${this._duration}|${this._time}`));
            }

            this.load = () => {

                const data = localStorage.getItem(this._srcid) || '';
                data.split('|').map(parseFloat).forEach((i, index) => {
                    if (isNaN(i) || !i)
                        i = 0;
                    switch (index)
                    {
                        case 0:
                            if (duration === undefined)
                                this._duration = i;
                            break;
                        case 1:
                            this._time = i;
                            break;
                    }
                });
            }
        } else {
            this.save = () => {
                setCookie(this._srcid, (`${this._duration}|${this._time}`))
            }
            this.load = () => {
                (getCookie(this._srcid) || '').split('|').map(parseFloat).forEach((i, index) => {
                    if (isNaN(i) || !i)
                        i = 0;
                    switch (index)
                    {
                        case 0:
                            if (duration === undefined)
                                this._duration = i;
                            break;
                        case 1:
                            this._time = i;
                            break;
                    }
                });


            }
        }

        this.load();
        if (this._time >= this._duration-2000)
            this._time = 0;
        if (this._time < 0)
            this._time = 0;

    }

    public get time(): number {
        return this._time;
    }

    protected stop = false;

    public setTimeAndStop(time:number)
    {
        this.time = time;
        this.stop = true;
    }

    public set time(value) {
        if (!this.stop) {
            this._time = value;
            this.save();
        }
    }

    public get duration(): number {
        return this._duration
    }

    public get srcid(): string {
        return this._srcid;
    }

}