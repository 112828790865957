import {VPaidProps} from "../Advertisement/VPaid";
import {getVast} from "../Advertisement";
import React, {useEffect, useRef} from "react";

import { VideoProps, Adv } from "./Types";
import { PlayerStateAndDispatch } from '../Reducer';
import {useRefState} from "../Utils";


/**
 * Vyhledani reklamy, a priprava pro prehravac
 *
 * @param state

 */

export const useAdv = (state: PlayerStateAndDispatch) : [Adv, (adv:Adv)=>void ] => {


    const hasAdv = state.adv;
    const [adv, setAdv, getAdv] = useRefState<Adv>(!hasAdv ? false : undefined);

    useEffect(()=>
    {
        if (hasAdv) {

            getVast(hasAdv).then((vast) => {

                if (!vast) {
                    setAdv(false);
                    return;
                }

                let videosources: {
                    type: string
                    src: string,
                    width: number,
                    height: number,
                    vzdalenost: number,
                    name: string
                }[] = [];

                let jssource = '';
                let isJs = false;

                const width = state.size.width;
                const height =state.size.height;


                const current = Math.sqrt(width*width + height*height);

                vast.sources.forEach((item) => {
                    if (item.type === 'application/javascript') {
                        jssource = item.src;
                        isJs = true;
                        return true;
                    } else {
                        videosources.push({
                            ...item,
                            name: item.width + "x" + item.height,
                            vzdalenost: Math.abs(Math.sqrt(item.width * item.width + item.height + item.height) - current)
                        })
                    }
                    return false;
                });

                if (isJs) {

                    const vpaidProps: VPaidProps = {
                        type: 'VPAIDPROPS',
                        width,
                        height,
                        volume: 0,
                        scriptUrl: jssource,
                        adStart: () => {

                        },
                        complete: () => {

                        },
                        vastData: vast
                    }
                    setAdv(vpaidProps);
                } else {
                    vast.vastTracker.on('clickthrough', url => {
                        window.open(url, '_blank');
                    });

                    let name = '';

                    videosources = videosources.sort((a, b) => {
                        return a.vzdalenost - b.vzdalenost
                    }).filter((a) => {
                        if (!name || name === a.name) {
                            name = a.name;
                            return true;
                        }
                        return false;
                    })

                    const videoProps: VideoProps = {
                        type: "VIDEOPROPS",
                        sources: videosources,
                        width: videosources[0].width,
                        height: videosources[0].height,
                        vastData: vast,
                        style: {
                            maxWidth: '100%',
                            maxHeight: '100%'
                        }
                    }
                    setAdv(videoProps);
                }

            }).catch(() => {
                setAdv(false);
            })

        }
    }, [hasAdv]);


    return [adv, setAdv];
}

