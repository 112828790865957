
import { VASTClient, VASTTracker } from 'vast-client';

type Source = {
    type: string,
    src: string,
    width: number,
    height: number
}

export type VastResponse = {
    companion?:any,
    vastTracker?: VASTTracker,
    client: VASTClient,
    sources:Array<Source>
}

const createSourceObjects = (media_files) =>
{

    var sourcesByFormat:Source[] = [], i, j, tech;


    for (var a = 0, b = media_files.length; a < b; a++) {
        var media_file = media_files[a];
        var source = {type:media_file.mimeType, src:media_file.fileURL};
        // Check if source can be played with this technology
        sourcesByFormat.push({
            type:media_file.mimeType as string,
            src: media_file.fileURL as string,
            width: media_file.width as number,
            height: media_file.height as number
        });
    }
    return sourcesByFormat;
}



const inicializaceReklamy = (client: VASTClient, response) : VastResponse|false=>
{
    if (!response)
        return false;


    const vast:VastResponse = {
        sources: [],
        client: client
    };

    if (response) {
        // we got a response, deal with it
        for (let adIdx = 0; adIdx < response.ads.length; adIdx++) {
            const ad = response.ads[adIdx];

            vast.companion = undefined;

            for (var creaIdx = 0; creaIdx < ad.creatives.length; creaIdx++) {
                var creative = ad.creatives[creaIdx], foundCreative = false, foundCompanion = false;
                if (creative.type === "linear" && !foundCreative) {

                    if (creative.mediaFiles.length) {
                        vast.sources = createSourceObjects(creative.mediaFiles);
                        vast.client = client;
                        if (!vast.sources.length)
                            return false;


                        vast.vastTracker = new VASTTracker(client, ad, creative);
                        foundCreative = true;
                    }

                } else if (creative.type === "companion" && !foundCompanion) {

                    vast.companion = creative;
                    foundCompanion = true;

                }
            }
            if (vast.vastTracker)
            {
                return vast;
            } else {
                return false;
            }
        }
    }
    return false;
}

export const getVast = async (url: string) : Promise<VastResponse|false>=>
{

    return new Promise<VastResponse|false>(async(resolve, reject)=>{


        const client = new VASTClient();
        let nothing = false;
        let timeout = setTimeout(()=>{
            nothing = true;
            resolve(false);
        },3000);

        try {


            const response = await client.get(url, {});

            const vastResponse = inicializaceReklamy(client, response);

            if (nothing)
                return;
            clearTimeout(timeout);
            resolve(vastResponse);
        }
        catch (e)
        {
            return false;
        }

    });
}