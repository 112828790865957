import React, {useEffect, useReducer, useState} from "react";
import {PlayerStateContext, PlayerStateDispatchContext, PlayerStateInitContext, PlayerState, PlayerReducerContext} from "../Reducer";
import Button from "./Button";

import Audio from '../Audio';
import {Text} from "./Text";
import {domconsole, useResponsiveBreakpoints} from "../Utils";

type Props = {
    src: string
    srcid?: string
    duration?: number
    title: string
    desc?: string
    adv?: string
    element: HTMLElement
    console: HTMLElement|undefined
    mode?: 'black'|'white'
}


export default  (props: Props) => {
//PlayerReducerContext, {} as Player, PlayerInitContext

    const [state, dispatch] = useReducer(PlayerReducerContext, {  title: props.title, src: props.src, adv: props.adv } as PlayerState, PlayerStateInitContext(props.src, props.element, props.srcid, props.duration, props.console, props.mode))
    const size = useResponsiveBreakpoints(props.element);

    useEffect(()=>{
        if (size.width !== state.size.width || size.height !== state.size.height)
            dispatch({
               type: "SIZE",
               payload: size
            });

        const style = props.element.style;
        if ((size.height+'px') !== style.height)
        {
            style.height = size.height + 'px';
        }
    }, [ Math.floor(size.height)])

    const text = true ? false: <Text title={props.title} desc={props.desc} element={state.element} size={state.size} mode={state.mode}/>;

    return <PlayerStateContext.Provider value={state}>
        <PlayerStateDispatchContext.Provider value={dispatch}>
            <PlayerStateContext.Consumer>{(state)=>
            {
                return <>
                    {text}
                    <Button status={state.status}/>
                    <Audio {...state}/>
                </>
            }}
            </PlayerStateContext.Consumer>
        </PlayerStateDispatchContext.Provider>
    </PlayerStateContext.Provider>
}