import React, {useEffect, useRef} from "react";

import {LOADING, PlayerStateAndDispatch, PLAYING, STOP} from '../Reducer';
import {Adv} from "./Types";
import {getCookie, setCookie, useRefState} from "../Utils";



const usePlayingEmpty = (state: PlayerStateAndDispatch, adv: Adv) : ((time:number)=>void) =>{
    return (time)=> {

    }
}

const usePlayingFull =  (state: PlayerStateAndDispatch, adv: Adv) => {


    const interval = useRef<ReturnType<typeof setInterval>>();
    const timing = useRef<number[]>([]);
    const lastTimeUpdate = useRef<Date>(new Date());

    const [playingCookie] = useRefState(new Date().getTime());

    useEffect(() => {
        return () => {
            if (interval.current)
                clearInterval(interval.current);
        }
    }, []);

    useEffect(() => {
        if (interval.current)
            clearInterval(interval.current);

        lastTimeUpdate.current = new Date();
        const kontrola = () => {

            const f = parseInt(getCookie('hpco') || '') || 0;
            if (f > playingCookie) {
                state.dispatch({
                    type: "STATUS",
                    payload: STOP
                });
                return;
            } else if (f !== playingCookie) {
                setCookie('hpco', playingCookie + '', 3600);
            }

            if (adv !== false)
                return;
            /**
             * kontroluje, zda probiha onTimeUpdate (funkce, ktera se vraci z funkce se chytne k teto udalost)
             * kontrola probiha co 2 sekundy
             * potrebuje mit aspon 5 udalosti, se vzdalenosti mensi nez sekunda
             */
            if (timing.current.length <= 5) {
                if (state.status !== LOADING)
                    state.dispatch({
                        type: "STATUS",
                        payload: LOADING
                    });

                if (new Date().getTime() - lastTimeUpdate.current.getTime() > 60000) {
                    lastTimeUpdate.current = new Date();
                    state.dispatch({
                        type: "AUDIOREFRESH"
                    });
                }
                return;
            }
            /**
             * odstrani starsi casy, nez je poslednich 5, nasledne se najde maximalni rozdil mezi casy
             */
            timing.current.splice(0, timing.current.length < 5 ? 0 : timing.current.length - 5);
            let max = 0;
            timing.current.reduce((previous, current, index) => {
                const now = Math.abs(current - previous);
                if (now > max) {
                    max = now;
                }
                return current;
            }, timing[0]);

            /**
             * v pripade, ze je ok, a tvari se, ze je loading, prehodi na play, v opacnem pripade na LOADING
             */
            if (max < 1000 && timing.current.length >= 5) {
                if (state.status !== PLAYING) {
                    state.dispatch({
                        type: "STATUS",
                        payload: PLAYING
                    })
                }
            } else if (state.status !== LOADING) {
                //console.log(max < 1000, state.status, timing.current.length)
                state.dispatch({
                    type: "STATUS",
                    payload: LOADING
                })
            }
        }
        interval.current = setInterval(kontrola, adv === false ? 2000 : 500);


    }, [state.status, adv])

    return (time: number) => {
        lastTimeUpdate.current = new Date();
        timing.current.push(time);
    }
}

let element: HTMLVideoElement | null = document.createElement('video');
let useEmpty = false;


if (('onProgress' in element || 'onprogress' in window) && ('onWaiting' in element|| 'onwaiting' in element)) {
    useEmpty = true;
}
element = null;

export const usePlaying = useEmpty ? usePlayingEmpty : usePlayingFull ;
