import Status, {LOADING, PLAYING, STOP} from "../Reducer/Status";
import React, {useContext} from "react";
import {PlayerStateContext, PlayerStateDispatchContext} from "../Reducer";
import classnames from 'classnames';
import  './Button.scss';

export default (props: {
    status: Status
}) =>{


    const dispatch = useContext(PlayerStateDispatchContext);

    const classes = {
        'play-button': true,
    }


    const classState = {
        'play-button': true,
        'play-button-state-stop': props.status === STOP,
        'play-button-state-loading': props.status === LOADING,
        'play-button-state-play': props.status === PLAYING
    }

    return <a href={'#'} style={{ display:'none' }} className={ classnames(classState) } onClick={(ev)=>{
            ev.preventDefault();
            if (props.status === STOP)
            {
                dispatch({
                    type: "STATUS",
                    payload: LOADING
                })
            }
            else
            {
                dispatch({
                    type: "STATUS",
                    payload: STOP
                })
            }
        }}>
        <div className={'play-button-background'}>
            <div className={'play-button-icon'}/>
            <div className={'play-button-loading play-button-loading-0'}/>
            <div className={'play-button-loading play-button-loading-1'}/>
            <div className={'play-button-loading play-button-loading-2'}/>
        </div>

    </a>
}