import React, {useContext, useEffect, useRef, useState} from 'react';
import {VastResponse} from "./Vast";


export type VPaidProps = {
    type: 'VPAIDPROPS',
    scriptUrl: string,
    complete: () => void,
    adStart: ()=>void,
    width: number,
    height: number,
    volume: number,
    vastData: VastResponse
}

export const VPaid = (props: VPaidProps) => {


    const [width, height] = ([props.width, props.height]);

    const iframe = useRef<HTMLIFrameElement | null>(null)

    const getVPaid = useRef<any|null>(null);
    const volume = useRef<number>(props.volume);

    const getVolume = () => {
        return volume.current;
    }
    const video = useRef<HTMLVideoElement|null>(null);
    if (video.current)
    {
        video.current.height = props.height;
        video.current.width = props.width;
        video.current.style.width = props.width + 'px';
        video.current.style.height = props.height + 'px';
    }
    useEffect(() => {

        let nothing = false;
        let waiting: ReturnType<typeof setTimeout>;


        if (iframe.current !== null) {


            const contentWindow = iframe.current.contentWindow;
            if (contentWindow) {
                waiting = setTimeout(i => {
                    nothing = true;
                    props.complete();
                }, 4000);

                const childDocument = contentWindow.document;
                const containerAd = childDocument.createElement('div');
                video.current = childDocument.createElement('video');
                const v = video.current;
                childDocument.body.appendChild(containerAd);
                containerAd.appendChild(video.current);
                v.id = 'video-vpaid';
                v.width = props.width;
                v.height = props.height;
                v.style.width = props.width + 'px';
                v.style.height = props.height + 'px';
                containerAd.id = 'container-vpaid';

                let vpaidLoader = childDocument.createElement('script');

                vpaidLoader.onload = function () {

                    if (nothing)
                        return;

                    // @ts-ignore
                    getVPaid.current = iframe.current.contentWindow.getVPAIDAd();

                    clearTimeout(waiting);

                    setupEvents(getVPaid.current, containerAd, v, props.width, props.height, props.vastData, props.complete, props.adStart, getVolume)
                };
                vpaidLoader.src = props.scriptUrl;
                childDocument.body.appendChild(vpaidLoader);
                return;
            }

        }
        props.complete();

    }, []);

    useEffect(()=>{
        if (getVPaid.current && getVPaid.current.setAdVolume)
            try {
                volume.current = props.volume;
                getVPaid.current?.setAdVolume(props.volume);
            }
            catch (e)
            {

            }
    }, [props.volume]);

    return <iframe ref={el => iframe.current = el}
                style={{
                    width: props.width+'px',
                    height: props.height+'px'
                }}
                width={props.width}
                height={props.height}
                marginWidth={0}
                marginHeight={0}
                frameBorder={0}
                scrolling="no"
        />

}


const setupEvents = (vpaid: any, containerAd: HTMLDivElement, video: HTMLVideoElement, width: number, height: number, vastResponse: VastResponse, complete: () => void, adStart: ()=>void, getVolume:()=>number) => {



    let addEventListener: (((name: string, func: any) => void) | false) = false;
    let removeEventListener: (((name: string, func: any) => void) | false) = false;

    if (vpaid.addEventListener)
        addEventListener = (name, func) => vpaid.addEventListener(name, func);
    else if (vpaid.subscribe)
        addEventListener = (name, func) => vpaid.subscribe(func, name);
    else if (vpaid.on)
        addEventListener = (name, func) => vpaid.on(func, name);

    if (vpaid.removeEventListener)
        removeEventListener = (name, func) => vpaid.removeEventListener(name, func);
    else if (vpaid.unsubscribe)
        removeEventListener = (name, func) => vpaid.unsubscribe(func, name);
    else if (vpaid.off)
        removeEventListener = (name, func) => vpaid.off(func, name);


    let removeEvents: {
        listener: (ev: any) => void,
        eventName: string
    }[] = [];
    let cekat: ReturnType<typeof setTimeout> | undefined = undefined;
    let interval = 0;
    let time = 0;


    let zacatekCalled = false;
    const zacatek = () => {
        if (zacatekCalled)
            return;
        cekat = setTimeout(ukonceni, 4000);

    }

    let ukonceniCalled = false;
    const ukonceni = () => {

        if (ukonceniCalled)
            return;

        ukonceniCalled = true;
        clearInterval(interval);
        removeEvents.forEach(m => {
            try {
                if (removeEventListener)
                    removeEventListener(m.eventName, m.listener);
            } catch (e) {

            }

        });
        complete();
    }


    ['AdStarted',
        'AdLoaded',
        'AdStopped',
        'AdSkipped',
        'AdLinearChange',
        'AdSizeChange',
        'AdExpandedChange',
        'AdSkippableStateChange',
        'AdDurationChange',
        'AdRemainingTimeChange',
        'AdVolumeChange',
        'AdImpression',
        'AdClickThru',
        'AdInteraction',
        'AdVideoStart',
        'AdVideoFirstQuartile',
        'AdVideoMidpoint',
        'AdVideoThirdQuartile',
        'AdVideoComplete',
        'AdUserAcceptInvitation',
        'AdUserMinimize',
        'AdUserClose',
        'AdPaused',
        'AdPlaying',
        'AdError',
        'AdLog'].forEach(eventName => {


        const listener = (ev) => {
            switch (eventName) {
                case 'AdSizeChange':
                    return;
                case 'AdLoaded':
                    vpaid.setAdVolume(getVolume());
                    vpaid.startAd();
                    return;
                case 'AdStarted':
                case 'AdPlaying':
                    if (cekat)
                        clearTimeout(cekat);
                    adStart();
                    return;
                case 'AdSkipped':
                case 'AdStopped':
                case 'AdError':
                    ukonceni();
                    return;
            }
        };


        try {
            addEventListener && addEventListener(eventName, listener);
            removeEvents.push({
                listener,
                eventName
            });
        } catch (e) {
            complete()
        }

    })
    zacatek();
    try {
        vpaid.initAd(width, height, 'normal', 0, {AdParameters: vastResponse.vastTracker.creative.adParameters}, {
            videoSlotCanAutoPlay: true,
            slot: containerAd,
            videoSlot: video
        });
    } catch (e) {

        ukonceni();
    }


};
