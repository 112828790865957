require('core-js/features/map');

import ReactDOM from 'react-dom';
import React from 'react';
import Player from './Player';
import striptags from "striptags";

import './style.scss';

type Data = {
    src: string,
    srcid?: string,
    duration?: number,
    title: string,
    desc?: string,
    adv?: string,
    console?: string,
    mode?: 'black' | 'white'
    backgroundImage?: string
}


if (typeof window['jQuery'] !== 'undefined') {

    jQuery.fn.helaxplayer = function (this: JQuery, ...options: any) {
        this.addClass('helax-player').each((index, element) => {

            const data = jQuery(element).data() as Data;
            if (!data.src)
                return;

            if (data.backgroundImage) {

                //ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~:/?#[]@!$&'()*+,;=
                //const r = /[a-zA-Z0-9\-._~:/?#\[\]@!$&'*+,;=]/;

                let backgroundImage = encodeURI(data.backgroundImage)
                backgroundImage = backgroundImage.replace(/([()])/g, (i) => `\\${i}`);
                jQuery(element).css('background-image', `url(${backgroundImage})`);
            }

            if (data.title) {

                try {
                    data.title = JSON.parse(data.title)
                } catch (e) {
                }
                if (data.title)
                    data.title = striptags(data.title);
            }
            if (data.desc) {
                try {
                    data.desc = JSON.parse(data.desc);
                } catch (e) {
                }
                if (data.desc) {
                    data.desc = striptags(data.desc);
                }
            }
            let pod: string[] = [];

            if (data.title)
                pod.push(`<div class='title'>${data.title}</div>`);
            if (data.desc)
                pod.push(`<div class='subtitle'>${data.desc}</div>`);
            if (pod.length) {
                jQuery(element).after(pod.join(''));
            }

            ReactDOM.render(<Player  {...data} element={element}
                                     console={data.console ? jQuery(data.console).get(0) : undefined}/>, element);
        })
        return this;
    };
}
