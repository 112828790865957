import React, {
    forwardRef,
    useLayoutEffect,
    useImperativeHandle,
    useRef,
    VideoHTMLAttributes,

} from "react";
import {Sources} from "./Source";

export type AudioReference = {
    play(): Promise<void>;

    pause(): void;

    readyState: number|undefined;

    load(): void;

    duration(): number | undefined;

    currentTime(time?: number): number | undefined;
}

export type Attributes =
    VideoHTMLAttributes<HTMLVideoElement>
    & { sources: Sources, beginCurrentTime?: number, volume?: number, domconsole: (iserr: boolean, ...args)=>void }


/*
 * Primo urceny prehravac, ktery realizuje prehravani videa a podcastu
 *
 * Funkce
 * load, play - v pripade, ze se prehravani sekne, da se znovu zavolat, aby se pokusil nahrat a prehrat
 * duration, currentTime - doba a konkretni cas podcastu
 *
 * Props stejne jako pro HTMLVideoElement + beginCurrentTime, source
 *      beginCurrentTime: pokud se zmeni, pokousi se nacist tuto pozici (nemusi byt definovano)
 *      source: pokud se zmeni, nacte se automaticky
 */

export const Audio = forwardRef<AudioReference, Attributes>((props, handler) => {

    const ref = useRef<HTMLVideoElement | null>(null);
    useImperativeHandle(handler, () => ({
        load() {
            ref.current?.load();
        },
        pause() {
            ref.current?.pause();
        },
        get readyState () {
            return  ref.current?.readyState;
        },
        play() {
            const def = ref.current?.play();
            if (!def)
                return Promise.reject();
            return def;
        },
        duration(): number | undefined {
            return ref.current?.duration;
        },
        currentTime(time): number | undefined {
            if (time !== undefined && ref.current) {
                ref.current.currentTime = time;
            }
            return ref.current?.currentTime;
        }
    }));

    // zajisteni, aby se nenacital porad podcast, takove vycisteni pameni a hlavne zruseni stahovani ze site
    useLayoutEffect(() => {
        return () => {
            const audioTag = ref.current;
            if (audioTag) {
                audioTag.addEventListener('pause', (ev) => {
                    const sources = audioTag.getElementsByTagName('source');
                    for (let i = 0; i < sources.length; i++) {
                        audioTag.removeChild(sources[i]);
                    }
                    audioTag.src = '';
                    audioTag.currentTime = 0;
                    audioTag.load();
                });
                audioTag.pause();
            }
        }
    }, []);

    const sources = props.sources;


    const newProps: VideoHTMLAttributes<HTMLVideoElement> & { sources?: any, beginCurrentTime?: any, volume?: any, domconsole?:any } = {
        ...props, playsInline: true, onContextMenu: (ev) => {
            ev.preventDefault();
            ev.stopPropagation();
        }
    };
    newProps.preload = "metadata";
    if (!newProps.width)
        newProps.width = 20;
    if (!newProps.height)
        newProps.height = 20;

    delete newProps.sources;
    delete newProps.beginCurrentTime;
    delete newProps.volume;
    delete newProps.domconsole;

    const changes0 = JSON.stringify(sources);


    useLayoutEffect(() => {
        const current = ref.current
        if (current) {
            current.pause();
            current.load();
            props.domconsole(false, 'Pause|load');
        }
    }, [changes0]);

    useLayoutEffect(() => {
        const current = ref.current;
        const cur = props.beginCurrentTime || 0;
        if (current &&
            (current.paused ||
                current.currentTime < (cur - .5)
                || current.currentTime > (cur + .5))) {
            current.currentTime = props.beginCurrentTime || 0;
            const promise = current.play();
            props.domconsole(false, 'play');
            if (promise) {
                promise.then(()=>props.domconsole(false, 'playing')).catch((e)=>props.domconsole(true, e));
            }
        }
    }, [changes0, props.beginCurrentTime || 0])

    useLayoutEffect(()=>{
        const current = ref.current;
        if (props.volume !== undefined && current) {
            let v = props.volume;
            if (v < 0)
                v = 0;
            if (v > 1)
                v = 1;
            current.volume = v;

            props.domconsole(false, 'volume')
        }
    }, [props.volume || -1])


    return <video ref={item => ref.current = item} {...newProps}>{sources.map(i => <source key={i.src} src={i.src}
                                                                                           type={i.type}/>)}</video>
});



