import React, {useContext, useEffect, useRef, useState, VideoHTMLAttributes} from "react";
import {LOADING, PlayerState, PlayerStateDispatchContext, PLAYING, STOP} from "../Reducer";
import {Sources} from "./Source";
import {Audio, AudioReference, Attributes} from "./Audio";
import {VPaid} from "../Advertisement";
import {Skip, SkipReference} from "./Skip";
import {useAdv} from "./useAdv";
import {usePlaying} from "./usePlaying";
import './index.scss';


import Position, {PositionReference} from "./Position";


const Item = (state: PlayerState) => {


    const positionReference = useRef<PositionReference | null>(null);
    const skipReference = useRef<SkipReference | null>(null);
    const audioReference = useRef<AudioReference | null>(null);

    const dispatch = useContext(PlayerStateDispatchContext);

    const [adv, setAdv] = useAdv({...state, ...{dispatch}});
    const [pingPlayed, setPingPlayed] = useState(adv === false);
    const addTime = usePlaying({...state, dispatch}, adv);



    let sources: Sources = [{
        src: 'https://radia.cz/build/ping.mp3',
        type: 'audio/mpeg'
    }];

    let renderSkip = () => {
        return <></>;
    }

    let renderIframe = () => {
        return <></>;
    }

    let renderBackground = () => {
        return <></>;
    }

    const audioRefresh = useRef(state.audioRefresh);

    useEffect(()=>{
        if (adv === false && audioRefresh.current !== state.audioRefresh) {

            audioReference.current?.load();
            audioReference.current?.play();
        }
    }, [state.audioRefresh])


    const audioProps: Attributes = {
        sources: sources, domconsole: state.domconsole, volume: 0.3, onError: () => {
            setPingPlayed(true)
        }, onEnded: (ev) => {

            setPingPlayed(true);
        }
    };
    if (pingPlayed) {

        switch (adv) {
            case false:
                // prehravani podcastu
                sources = [{
                    src: state.src,
                    type: 'audio/mpeg'
                }];

                Object.assign(audioProps, {
                    ...audioProps,
                    sources,
                    volume: state.volume,
                    beginCurrentTime: state.position.time / 1000,
                    onEnded: () => {
                        dispatch({
                            type: "STATUS",
                            payload: STOP
                        });
                    },
                    onWaiting: (event) => {
                        if ((audioReference.current?.readyState || 0) <= 2 && state.status === PLAYING) {
                            dispatch({
                                type: "STATUS",
                                payload: LOADING
                            })
                        }
                    },
                    onProgress: (event) => {

                        if ((audioReference.current?.readyState || 0) >= 2 && (state.status !== PLAYING)) {
                            dispatch({
                                type: "STATUS",
                                payload: PLAYING
                            })
                        }
                    },
                    onError: (event) => {
                        dispatch({
                            type: "STATUS",
                            payload: STOP
                        });
                    },
                    onLoadedMetadata: (event) => {
                        positionReference.current?.duration(Math.round((audioReference.current?.duration() || 0) * 1000));
                    },
                    onTimeUpdate: (event) => {
                        addTime(event.timeStamp);
                        positionReference.current?.time(Math.round((audioReference.current?.currentTime() || 0) * 1000));
                    }
                } as Attributes);
                break;
            case undefined:

                //cekani na stazeni reklamy
                break;
            default:
                renderBackground = () => {
                    return <div className={'video-advertisement-background'}/>
                };
                renderSkip = () => {
                    return <Skip ref={skipReference}
                                 volume={state.volume}
                                 silent={(silent) => {
                                     if (silent)
                                         dispatch({
                                             type: "VOLUME",
                                             payload: 0
                                         })
                                     else
                                         dispatch({
                                             type: "VOLUME",
                                             payload: 1
                                         })

                                 }}
                                 skip={() => {

                                     setAdv(false);
                                     dispatch({
                                         type: "VOLUME",
                                         payload: 1
                                     });
                                 }}/>
                }
                if (adv.type === "VIDEOPROPS") {
                    // pokud je(jsou) to video soubor(y), to je hlavne u reklamy
                    sources = adv.sources;
                    if (state.volume === 0 && !adv.vastData.vastTracker.muted) {
                        adv.vastData.vastTracker.setMuted(true);
                        state.domconsole(false, 'mute');
                    } else if (state.volume !== 0 && adv.vastData.vastTracker.muted) {
                        adv.vastData.vastTracker.setMuted(false);
                        state.domconsole(false, 'unmute');
                    }
                    Object.assign(audioProps, {
                        ...audioProps,
                        sources: sources,
                        className: 'video-advertisement',
                        width: adv.width,
                        height: adv.height,
                        style: {
                            width: state.size.width + 'px',
                            height: state.size.height + 'px'
                        },
                        volume: state.volume,
                        onClick: () => {
                            adv.vastData.vastTracker.click();
                        },
                        onLoadedData: (event) => {

                        },
                        onEnded: () => {
                            adv.vastData.vastTracker.complete();
                            setAdv(false);
                            dispatch({
                                type: "VOLUME",
                                payload: 1
                            });
                        },
                        onLoadedMetadata: (event) => {
                            if (!adv.vastData.vastTracker.assetDuration) {
                                const duration = audioReference.current?.duration();
                                if (duration)
                                    adv.vastData.vastTracker.setDuration(duration);
                            }
                            skipReference.current?.duration(adv.vastData.vastTracker.assetDuration);
                        },
                        onError: () => {
                            setAdv(false);
                            dispatch({
                                type: "VOLUME",
                                payload: 1
                            });
                        },
                        onTimeUpdate: (event) => {

                            adv.vastData.vastTracker.trackImpression();
                            const cur = audioReference.current?.currentTime();
                            if (cur !== undefined) {
                                adv.vastData.vastTracker.setProgress(cur);
                                skipReference.current?.time(cur);
                            }
                        }
                    } as Attributes);
                } else {
                    // pro vpaid se pouzije primo udelany element
                    renderIframe = () => {

                        return <div className={'video-advertisement-iframe'}><VPaid {...adv} width={state.size.width}
                                                                                    height={state.size.height}
                                                                                    adStart={() => {
                                                                                        skipReference.current?.start(10);
                                                                                        //this.skipTag?.start(10);
                                                                                    }} complete={() => {
                            setAdv(false);
                            dispatch({
                                type: "VOLUME",
                                payload: 1
                            });
                        }} volume={state.volume}/></div>
                    }
                }
        }
    }


    return <>
        <Audio ref={audioReference} {...audioProps}/>
        {renderBackground()}
        <Position ref={positionReference} element={state.element} mode={state.mode}/>
        {renderIframe()}
        {renderSkip()}
    </>

}

export default (state: PlayerState) => {
    return <>
        {state.status === STOP ? <Position element={state.element} mode={state.mode}/> : false}
        {state.status !== STOP ? <Item {...state}  /> : false}
    </>;


}