import React, {
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
    ForwardRefRenderFunction,
    AnchorHTMLAttributes,

} from 'react';
import classnames from 'classnames';
import {setCookie, useRefState} from "../Utils";

const TIMEOUT = "TIMEOUT";
const BOOLEAN = "BOOLEAN";

type TimeoutType  = {
    payload: ReturnType<typeof setTimeout>
    type: typeof TIMEOUT
}

type BooleanType = {
    payload: boolean
    type: typeof BOOLEAN
}

type WaitingType = BooleanType | TimeoutType;

export interface SkipReference {

    time: (time: number) => void,
    duration: (duration: number) => void,
    start: (duration: number)=> void,


}

type Props = {
    skip: () => void
    silent: (silent: boolean)=>void
    volume: number
    //mode: 'black'|'white'
}

const MinForSkip = 10;
const MaxSkip = 10;

const SkipType: ForwardRefRenderFunction<SkipReference, Props> = (
    props,
    imperativeRef
) => {

    const [time, setTime] = useRefState(0);

    const [duration, setDuration] = useRefState(0);

    const [waiting, setWaiting] = useRefState<ReturnType<typeof setTimeout> | true | false>(()=>{
        return setTimeout(()=>{setWaiting(false);},(MinForSkip+5) * 1000)
    });

    const interval = useRef<ReturnType<typeof setInterval>>()

    useEffect(()=>{
        return ()=>{
            if (interval.current)
                clearInterval(interval.current);
        }
    },[])

    useImperativeHandle(imperativeRef, ()=>({
        start: (duration: number)=>{
            if (interval.current)
                return;
            setDuration(duration)
            const begin = new Date().getTime();
            interval.current = setInterval(()=>{
                setTime((new Date().getTime() - begin)/1000)
            }, 300);
        },
        time: (time: number) =>{
            setTime(time);
        },
        duration: (duration:number)=>{
            setDuration(duration);
        }
    }));


    let skip = false;

    let minutes = MaxSkip - time;
    if (minutes < 1)
        minutes = 0;

    const classNames = {
        'video-advertisement-skip': true,
        'video-advertisement-skip-enable': false
    };

    const classNames1 = {
        'video-advertisement-mute': true,
        'active': props.volume != 0
    };

    const aProps = {
        href: '#',

        onClick: (ev)=>{
            ev.preventDefault();
        }
    } as AnchorHTMLAttributes<HTMLAnchorElement>



    let text = `Přeskočit reklamu za ${Math.floor(minutes)}`;


    if (minutes < 1 || (waiting === false))
    {
        text = 'Přeskočit...'
        setCookie("advp", "1", 15* 60);
        //classNames['radiacz-skip-enable'] = true;
        aProps.onClick = (ev)=>{
            ev.preventDefault();
            props.skip();
        }
    }
    else if (duration < MinForSkip)
    {
        if (!duration)
            text = 'Reklama...';
        else
            text = `Podcast bude hrát za ${Math.floor(duration - time)}`;
    }


    aProps.className = classnames(classNames);
    //aProps.style = { color: props.mode };
    return <>
        <a {...aProps}>{text}</a>
        <a href='#' className={classnames(classNames1)} onClick={(ev)=>{
            ev.preventDefault();
            if (props.volume > 0)
            {
                props.silent(true);
            }
            else
            {
                props.silent(false);
            }

        }}/>
    </>
}

export const Skip = forwardRef(SkipType);