import React, {
    Component,
    useContext,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useRef,
    useMemo
} from "react";
import {PlayerState, PlayerStateContext, PlayerStateDispatchContext} from "../Reducer";
import Slider from 'rc-slider';
import {SliderProps} from "rc-slider/es/Slider";
import {useRefState} from "../Utils";


/**
 *
 * Zobrazuje pozici podcastu (jak slider tak i cas samotny)
 * Pokud zacnu menit, ceka se, dokud se nepusti mys, pak se nastavi Position (znemozni se zmena, dokud se nezmeni globalni stav - nova Position).
 * Pro audio predava referenci na time a duration (jak se prehrava audio)
 *
 */


export interface PositionReference {
    time: (time: number) => void,
    duration: (duration: number) => void,

}

const ViewableTime = (allowedIndex: number, showType: boolean, duration: number, base: number): string => {
    base = Math.round((showType ? base : (duration - base)) / 1000);
    const sec = base % 60;
    let prev = (base - sec) / 60;
    const minutes = prev % 60;
    const hours = (prev - minutes) / 60;
    let remove = true;
    return (showType ? '' : '-') + ([hours, minutes, sec].map(i => {
        if (i < 10) {
            return '0' + i;
        }
        return "" + i;
    }).filter((i, index) => {
        return allowedIndex <= index;
    }).join(':'))
}


export default forwardRef<PositionReference, { mode:'black'|'white', setPosition?: (time: number) => void, time?: number, element: HTMLElement }>((props, handler) => {

    const {position, src} = useContext(PlayerStateContext);
    // jak velky cas se ma zobrazit
    const allowedIndex = useMemo(() => {
        if (position.duration > 60 * 1000 * 60)
            return 0;
        else if (position.duration > 60 * 1000)
            return 1;
        return 2;
    }, [position.duration]);

    // zobrazeno od nebo do
    const [showType, setShowType] = useState(true);
    const dispatch = useContext(PlayerStateDispatchContext);
    const [time, setTime] = useRefState(position.time);
    const [newTime, setNewTime, getNewTime] = useRefState<undefined | number>(undefined);

    useImperativeHandle(handler, () => ({
        time: (time: number) => {
            setTime(time);
            position.time = time;
        },
        duration: (duration: number) => {
            if (duration !== position.duration) {
                dispatch({
                    type: "DURATION",
                    src,
                    srcid: position.srcid,
                    duration
                })
            }
        }
    }));

    const nprops = {
        min: 0,
        max: position.duration,
        onAfterChange: (ev) => {

            const t = getNewTime();
            if (t) {

                position.setTimeAndStop(t);
                setNewTime(undefined);
                setTime(t);
                dispatch({
                    type: "POSITION"
                });
            }
        },
        step: 1,
        value: newTime !== undefined ? newTime : time,
        onChange: (ev) => {
            setNewTime(ev);
        }

    } as SliderProps;


    let base = newTime !== undefined ? newTime : time
    const cas = ViewableTime(allowedIndex, showType, position.duration, base);

    return <>
        {position.duration ? <a style={{color: props.mode}}className={'audio-position-text'} href={'#'} onClick={(ev) => {
            ev.preventDefault();
            setShowType(!showType);
        }}>{cas}</a> : false}
        <div className={'audio-position'}>
            <Slider {...nprops}/>
        </div>
    </>
});
