import Status, {PLAYING, STOP, LOADING} from './Status';

export {PLAYING, STOP, LOADING} from './Status';
import React, {createContext} from "react";
import {Position} from "./Position";

import md5 from 'blueimp-md5'
import {domconsole, getRect} from "../Utils";
import {createTracing} from "trace_events";

type StatusAction = {
    type: 'STATUS',
    payload: Status
}

type InitAction = {
    type: 'INIT',
    srcid?: string,
    src: number,
    duration?: number,
    element: HTMLElement,
    console?: HTMLElement
}

type ChangeDuration = {
    type: "DURATION",
    srcid?: string,
    src: string,
    duration?: number
}

type ChangePosition = {
    type: "POSITION"
}

type AudioRefresh = {
    type: "AUDIOREFRESH"
}

type VolumeRefresh = {
    type: "VOLUME",
    payload: number
}

type SizeRefresh = {
    type: "SIZE",
    payload: {
        width: number,
        height: number
    }
}

export type Actions =
    StatusAction
    | InitAction
    | ChangeDuration
    | ChangePosition
    | AudioRefresh
    | VolumeRefresh
    | SizeRefresh;

const StatusReducer = (payload: Status = STOP, action: Actions): Status => {
    switch (action.type) {
        case 'STATUS':
            if (payload === STOP && action.payload !== STOP)
            {
                try {
                    gtag("event", "podcast");
                }
                catch (e) {}

            }
            return action.payload;
    }
    return payload;
}

const VolumeReducer = (payload: number = 1, action: Actions): number => {
    switch (action.type) {
        case "VOLUME":
            return action.payload;
    }
    return payload;
}

const AdvReducer = (payload: string | null = null, action: Actions): string | null => {
    return payload || null;
}
const TitleReducer = (payload: string, action: Actions): string | null => {
    return payload;
}

const PositionReducer = (payload: Position, action: Actions): Position => {
    switch (action.type) {
        case "INIT":
        case "DURATION":
            const srcid = action.srcid || md5(action.src); // kdyby neexistovalo id, pak se vygeneruje
            return new Position(srcid, action.duration);
        case "POSITION":
            const p = payload.clone();
            return p;

    }
    return payload;
}


const SizeReducer = (payload: { width: number, height: number } = {
    width: 0,
    height: 0
}, action: Actions): { width: number, height: number } => {
    switch (action.type) {
        case 'INIT':
            return getRect(action.element.offsetWidth, action.element.offsetHeight);
        case 'SIZE':
            if (payload.width !== action.payload.width || payload.height !== action.payload.height) {
                return {
                    width: action.payload.width,
                    height: action.payload.height
                }
            }
    }

    return payload;
}

const AudioRefreshReducer = (payload = 0, action: Actions) => {

    switch (action.type) {
        case "AUDIOREFRESH":
            return payload + 1;
    }
    return payload;
}

const DomConsoleReducer = (payload: () => {}, action: Actions): ((iserr: boolean, ...args: any) => void) => {
    switch (action.type) {
        case 'INIT':

            if (action.console) {

                const dom = domconsole.bind(null, action.console);
                dom(false, __webpack__);
                return dom;
            } else {
                return () => {
                };
            }
    }
    return payload
}
const ElementReducer = (payload: HTMLElement, actions: Actions) => {
    switch (actions.type) {
        case "INIT":
            return actions.element;
    }
    return payload;
}

const PingReducer = (payload: string = 'https://radia.cz/build/ping.mp3', actions: Actions) => {
    if (actions.type === 'INIT') {
        let ping = 'https://radia.cz/build/ping.mp3';
        if (document?.currentScript) {
            if ("src" in document.currentScript) {
                const src: string = document.currentScript.src;
                if (src)
                {

                    return src.replace(/[^\/]+\.js(\?.*)?$/, 'ping1.mp3')
                }
            }
        }
    }
    return payload;
}


export type PlayerState = {
    status: Status // stop, loading, playing
    src: string // adresa mp3
    audioRefresh: number // pro refresh audia, zkousi znovi nacist a nastavit cas po zmene cisla
    title: string | null // nazev
    adv: string | null // url reklamy
    position: Position // pozice prehravani
    volume: number // nastaveni volume
    element: HTMLElement // element pro zjisteni velikost (reklama)
    domconsole: (iserr: boolean, ...args: any) => void
    ping: string
    mode: 'black'|'white'
    size: {
        width: number,
        height: number
    }

}

const PlayerStateReducer = {
    size: SizeReducer,
    status: StatusReducer,
    adv: AdvReducer,
    title: TitleReducer,
    position: PositionReducer,
    audioRefresh: AudioRefreshReducer,
    volume: VolumeReducer,
    element: ElementReducer,
    ping: PingReducer,
    domconsole: DomConsoleReducer
}

export type PlayerStateAndDispatch = PlayerState & { dispatch: React.Dispatch<Actions> }

export const PlayerStateInitContext = (src: string, element: HTMLElement, srcid?: string, duration?: number, console?: HTMLElement, mode?: 'black'|'white') => {


    return (state: PlayerState = {} as PlayerState): PlayerState => {

        for (let i in PlayerStateReducer) {
            state[i] = PlayerStateReducer[i](state[i], {type: 'INIT', src, element, srcid, duration, console});
        }

        switch (mode)
        {
            case 'black':
            case 'white':
                state.mode = mode;
                break;
            default:
                state.mode = 'black';
        }

        return state as PlayerState;
    }
}

export const PlayerReducerContext = (state: PlayerState, action: Actions): PlayerState => {

    const newState = {};
    let changes = false;
    for (let i in PlayerStateReducer) {
        newState[i] = PlayerStateReducer[i](state[i], action);
        changes = changes || newState[i] !== state[i];
    }
    if (changes)
        return {...state, ...newState} as PlayerState;
    else
        return state;
}

export const PlayerStateContext = createContext<PlayerState>(PlayerStateInitContext('', document.createElement('div'), '', 0, undefined)());
export const PlayerStateDispatchContext = createContext<React.Dispatch<Actions>>((action: Actions) => PlayerStateInitContext('', document.createElement('div'), '', 0, undefined)());


